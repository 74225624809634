import React from 'react';
import cx from 'classnames';
import Skeleton from '@common/Skeleton';

const Menu = () => {
    const data = ['sm1', 'sm2', 'sm3', 'sm4', 'sm5'];

    return (
        <div className="menu-wrapper">
            <ul className="nav" role="presentation" id="header-nav-menubar-skeleton">
                {data.map((idx) => (
                    <li
                        key={idx}
                        role="menuitem"
                        aria-label="Navigation Skeleton"
                        id={`header-menuitem-${idx}`}
                        className={cx(
                            'text-base',
                            'font-medium',
                            'tracking-normal',
                            'px-4',
                            'py-[13px]',
                            'border-b-[2px] border-b-neutral-white',
                            'hover:text-primary-700',
                            'hover:border-b-neutral-black',
                        )}
                    >
                        <Skeleton height={21} />
                    </li>
                ))}
            </ul>
            <style jsx global>
                {`
                    /* menu list */
                    .nav > li {
                        float: left;
                    }
                `}
            </style>
        </div>
    );
};

export default Menu;
