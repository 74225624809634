const useUserAgent = () => {
    let isWindows = false;
    let isAndroid = false;
    let isIOS = false;
    try {
        const userAgent = navigator?.userAgent || navigator?.vendor || window.opera;
        // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) isWindows = true;
        if (/android/i.test(userAgent)) isAndroid = true;
        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) isIOS = true;
        return {
            isWindows,
            isAndroid,
            isIOS,
        };
    } catch (err) {
        return {
            isWindows,
            isAndroid,
            isIOS,
        };
    }
};

export default useUserAgent;
