/* eslint-disable indent */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable */

import React from 'react';
import Image from '@common_image';
import Typography from '@common_typography';
import DialogSearch from '@common_dialogsearch';
import DesktopHeaderSkeleton from '@core_modules/theme/components/header/components/v1/adaptive/desktop.skeleton';
import MenuSkeleton from '@core_modules/theme/components/header/components/v1/mcategory.skeleton';
import dynamic from 'next/dynamic';
import config from '@config';
import cx from 'classnames';
import { useRouter } from 'next/router';
import { BREAKPOINTS } from '@theme_vars';
import useMediaQuery from '@hook/useMediaQuery';
import { getCategories } from '@core_modules/theme/services/graphql';

import Button from '@common_button';
import Drawer from '@common_drawer';
import Tabs from '@common_tabs';
import Bars3Icon from '@heroicons/react/24/solid/Bars3Icon';

const Menu = dynamic(() => import('@core_modules/theme/components/header/components/v1/mcategory'), { ssr: true });
// const ProductCompareIcon = dynamic(() => import('@core_modules/catalog/plugins/ProductCompare'), { ssr: true });
const ShoppingBagIcon = dynamic(() => import('@plugin_shoppingbag'), { ssr: true });
const UserInfo = dynamic(() => import('@core_modules/theme/components/header/components/v1/adaptive/plugin/userinfo'), { ssr: false });

const BurgerMenuCategories = dynamic(() => import('@core_modules/theme/components/header/components/burgermenu/categories'), { ssr: false });
const BurgerMenuAccount = dynamic(() => import('@core_modules/theme/components/header/components/burgermenu/account/index'), { ssr: false });
const InstallMobile = dynamic(() => import('@core_modules/theme/components/CustomPWAInstall/mobile'), { ssr: true });

const DesktopHeader = (props) => {
    const { t, storeConfig, isLogin, customer, dataMenu, loadingMenu, handleLogout, deviceWidth } = props;
    const { modules } = config;
    const router = useRouter();
    const isCartPage = props?.pageConfig?.pageType === 'cart';
    const [open, setOpen] = React.useState(false);
    const [isSearchShown, setIsSearchShown] = React.useState(false);
    const [openBurgerMenu, setOpenBurgerMenu] = React.useState(false);

    const { data } = getCategories();

    const { isDesktop, screenWidth } = useMediaQuery();
    

    const dataMenusHeader = [
        {
            enabled: !isLogin,
            id: 'sigin_signup',
            name: `${t('common:label:signin')} / ${t('common:label:signup')}`,
            url_path: '/customer/account/login',
            icon: React.cloneElement((
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path fillRule="evenodd" clipRule="evenodd" d="M17 4.25C17 3.00736 15.9926 2 14.75 2H9.25C8.00736 2 7 3.00736 7 4.25V6.25C7 6.66421 7.33579 7 7.75 7C8.16421 7 8.5 6.66421 8.5 6.25V4.25C8.5 3.83579 8.83579 3.5 9.25 3.5H14.75C15.1642 3.5 15.5 3.83579 15.5 4.25V15.75C15.5 16.1642 15.1642 16.5 14.75 16.5H9.25C8.83579 16.5 8.5 16.1642 8.5 15.75V13.75C8.5 13.3358 8.16421 13 7.75 13C7.33579 13 7 13.3358 7 13.75V15.75C7 16.9926 8.00736 18 9.25 18H14.75C15.9926 18 17 16.9926 17 15.75V4.25Z" fill="#121212"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M1 10C1 9.58579 1.33579 9.25 1.75 9.25H11.2955L10.2483 8.30747C9.94039 8.03038 9.91543 7.55616 10.1925 7.24828C10.4696 6.94039 10.9438 6.91543 11.2517 7.19253L13.7517 9.44253C13.9098 9.58476 14 9.78738 14 10C14 10.2126 13.9098 10.4152 13.7517 10.5575L11.2517 12.8075C10.9438 13.0846 10.4696 13.0596 10.1925 12.7517C9.91543 12.4438 9.94039 11.9696 10.2483 11.6925L11.2955 10.75H1.75C1.33579 10.75 1 10.4142 1 10Z" fill="#121212"/>
                </svg>
            ))
        },
        {
            enabled: isLogin,
            id: 'my_account',
            name: `${t('common:menu:myaccount')}`,
            url_path: '/customer/account',
        },
    ];

    const dataMenusFooter = [
        {
            enabled: isLogin,
            id: '/wishlist',
            name: `${t('common:label:myWishlist')}`,
            url_path: '/wishlist',
        },
        {
            enabled: isLogin,
            id: 'product_compare',
            name: `${t('common:label:compareProduct')}`,
            url_path: '/catalog/product_compare',
        },
        {
            enabled: isLogin,
            id: 'logout',
            name: `${t('common:label:logout')}`,
            url_path: null,
            onClick: handleLogout
        },
    ];
    
    const dataMenus = React.useMemo(() => {
        const dataMenuFinal = [];
        if(data && data?.categories?.items[0]?.children) {
            for(const dataMenuIndex in data.categories.items[0].children) {
                const dataMenuItem = data.categories.items[0].children[dataMenuIndex];
                dataMenuFinal.push(dataMenuItem);
            }
            return dataMenuFinal;
        }
        return [];
    }, [data]);

    const burgerMenuData = [
        {
            title: 'Menu',
            content: data && (
                <BurgerMenuCategories
                    isLogin={isLogin}
                    setOpenBurgerMenu={setOpenBurgerMenu}
                    data={dataMenus}
                    dataMenusHeader={dataMenusHeader}
                    dataMenusFooter={dataMenusFooter}
                    storeConfig={storeConfig}
                />
            ),
            type: 'react-component',
        },
        {
            title: 'Account',
            content: <BurgerMenuAccount isLogin={isLogin} handleLogout={handleLogout} />,
            type: 'react-component',
        },
    ];

    const PopoverContent = () => {
        return (
            <ul className={cx('my-account-list__wrapper')}>
                <a href="/customer/account">
                    <li
                        key={0}
                        className={cx('my-account-list__item', 'py-2', 'px-2', 'text-left', 'hover:cursor-pointer', 'hover:bg-neutral-100')}
                        onClick={() => router.push('/customer/account')}
                    >
                        <Typography className={cx('currency-list__text', 'text-neutral-700')}>{t('common:menu:myaccount')}</Typography>
                    </li>
                </a>
                <a href="/wishlist">
                    <li
                        key={1}
                        className={cx('my-account-list__item', 'py-2', 'px-2', 'text-left', 'hover:cursor-pointer', 'hover:bg-neutral-100')}
                        onClick={() => router.push('/wishlist')}
                    >
                        <Typography className={cx('currency-list__text', 'text-neutral-700')}>{t('common:label:myWishlist')}</Typography>
                    </li>
                </a>
                {modules.productcompare.enabled && (
                    <a href="/catalog/product_compare">
                        <li
                            key={2}
                            className={cx('my-account-list__item', 'py-2', 'px-2', 'text-left', 'hover:cursor-pointer', 'hover:bg-neutral-100')}
                            onClick={() => router.push('/catalog/product_compare')}
                        >
                            <Typography className={cx('currency-list__text', 'text-neutral-700')}>{t('common:label:compareProduct')}</Typography>
                        </li>
                    </a>
                )}
                <li
                    key={3}
                    className={cx('my-account-list__item', 'py-2', 'px-2', 'text-left', 'hover:cursor-pointer', 'hover:bg-neutral-100')}
                    onClick={() => handleLogout()}
                >
                    <Typography className={cx('currency-list__text', 'text-primary-700')}>{t('common:label:logout')}</Typography>
                </li>
            </ul>
        );
    };

    if (screenWidth === 0) {
        return <DesktopHeaderSkeleton />
    }

    return (
        <div
            className={cx(
                'desktop-header',
                'transition-transform',
                'delay-300',
                'duration-500',
                'ease-in-out',
                'shadow-[0_1px_3px_0px_rgba(0,0,0,0.10)]',
                {
                    'hidden-this-desktop': deviceWidth < BREAKPOINTS.xl,
                },
            )}
        >
            {!isDesktop ? (
                <>
                    <InstallMobile {...props} />
                    <Drawer
                        open={openBurgerMenu}
                        handleClose={() => setOpenBurgerMenu(false)}
                        position="right"
                        className={cx('mobile:w-[320px]', 'tablet:w-[396px]', 'desktop:w-[540px]', {
                            'mobile:right-[320px] tablet:right-[396px] desktop:right-[540px]': openBurgerMenu,
                        })}
                    >
                        <Tabs
                            showTabHeader={false}
                            data={burgerMenuData}
                            tabHasContent
                            tabWrapperClassName={cx('border-none')}
                            tabTitleWrapperClassName={cx('grid', 'grid-cols-2')}
                            tabTitleClassName={cx('border-none', '!text-neutral-700', 'text-2md', 'font-semibold')}
                            tabTitleActiveClassName={cx('border-none', '!text-neutral-700', 'text-2md', 'font-semibold')}
                            tabTitleListClassName={cx('bg-neutral-100')}
                            tabTitleListActiveClassName={cx('bg-neutral-white', 'border-b-[1px]', 'border-b-neutral-400')}
                            tabContentClassName={cx('pt-0')}
                        />
                    </Drawer>
                </>
            ) : null}
            <div className={cx('middle-header')}>
                <div
                    className={cx(
                        'middle-header__wrapper',
                        'm-[0_auto]',
                        'desktop:grid desktop:grid-cols-[1fr_8fr_1fr]',
                        'items-center',
                        'gap-x-6',
                        'desktop:max-w-[1440px]',
                        'py-space-400 px-space-400',
                        'tablet:py-space-500 tablet:px-space-600',
                        'desktop:px-space-1000 desktop:py-space-400',
                        'flex w-full justify-between',
                    )}
                >
                    <div
                        className={cx('middle-header__logo', 'basis-[272px]', 'h-[auto] desktop:h-[44px]', 'flex', 'items-center', 'cursor-pointer')}
                    >
                        <a href="/">
                            <Image
                                styleContainer={{
                                    width: `${storeConfig?.logo_width || (isDesktop ? 120 : 74)}px`,
                                    height: `${storeConfig?.logo_height || (isDesktop ? 52 : 34)}px`,
                                    paddingTop: 0,
                                }}
                                className="header-middle__logo-link"
                                src={`${storeConfig.secure_base_media_url}logo/${storeConfig.header_logo_src}`}
                                alt={storeConfig.default_title}
                                width={storeConfig?.logo_width || (isDesktop ? 120 : 74)}
                                height={storeConfig?.logo_height || (isDesktop ? 52 : 34)}
                                storeConfig={storeConfig}
                                lazy={false}
                            />
                        </a>
                    </div>
                    {isDesktop ? (
                        <div
                            className={cx(
                                'bottom-header',
                                'tablet:max-w-[768px]',
                                'desktop:max-w-[1280px]',
                                'mobile:hidden tablet:hidden',
                                'pl-[18px]',
                            )}
                        >
                            <div className="flex flex-row menu-category">
                                <div className="xs:basis-full menu-middle">
                                    {loadingMenu ? <MenuSkeleton /> : <Menu data={dataMenu} storeConfig={storeConfig} />}
                                </div>
                            </div>
                        </div>
                    ) : null}
                    <div className={cx('middle-header__statusicon')}>
                        <div className={cx('middle-header__statusicon__left-section', 'flex', 'flex-row', 'gap-space-500', 'desktop:px-2')}>
                            {/* {modules.productcompare.enabled && (
                                <div className={cx('product-compare')}>
                                    <ProductCompareIcon withLink isLogin={isLogin} />
                                </div>
                            )} */}
                            <div className={cx('search-desktop', 'flex justify-center items-center')}>
                                <DialogSearch variant="plain" open={isSearchShown} setOpen={setIsSearchShown} storeConfig={storeConfig} />
                            </div>
                            <div id="header-shoppingBag-icon" className={cx('shopping-bag')}>
                                <ShoppingBagIcon withLink isCartPage={isCartPage} storeConfig={storeConfig} />
                            </div>
                            {isDesktop ? (
                                <div
                                    className={cx(
                                        'middle-header__statusicon__right-section__account',
                                        'hover:cursor-pointer',
                                        'flex',
                                        'justify-start',
                                        'group',
                                    )}
                                >
                                    <UserInfo
                                        t={t}
                                        isLogin={isLogin}
                                        customer={customer}
                                        open={open}
                                        setOpen={setOpen}
                                        PopoverContent={PopoverContent}
                                    />
                                </div>
                            ) : (
                                <div className={cx('bottom-header-mobile__burger-menu', 'flex justify-center items-center')}>
                                    <Button
                                        className={cx(
                                            '!px-0',
                                            '!pb-0',
                                            '!pt-0',
                                            '!ml-0',
                                            'hover:shadow-none',
                                            'focus:shadow-none',
                                            'active:shadow-none',
                                            'active:shadow-none',
                                        )}
                                        onClick={() => {
                                            setOpenBurgerMenu(true);
                                        }}
                                        icon={<Bars3Icon />}
                                        iconProps={{ className: cx('text-neutral-700', 'w-[24px]', 'h-[24px]') }}
                                        iconOnly
                                        variant="tertiary"
                                        classNameText={cx('!text-neutral-700')}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DesktopHeader;
