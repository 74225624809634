import React from 'react';
import MenuSkeleton from '@core_modules/theme/components/header/components/v1/mcategory.skeleton';
import cx from 'classnames';
import Skeleton from '@common/Skeleton';

const DesktopHeaderSkeleton = () => (
    <div
        className={cx(
            'desktop-header',
            'transition-transform',
            'delay-300',
            'duration-500',
            'ease-in-out',
            'shadow-[0_1px_3px_0px_rgba(0,0,0,0.10)]',
            'hidden-this-desktop',
        )}
    >
        <div className={cx('middle-header')}>
            <div
                className={cx(
                    'middle-header__wrapper',
                    'm-[0_auto]',
                    'desktop:grid desktop:grid-cols-[1fr_8fr_1fr]',
                    'items-center',
                    'gap-x-6',
                    'desktop:max-w-[1440px]',
                    'py-space-400 px-space-400',
                    'tablet:py-space-500 tablet:px-space-600',
                    'desktop:px-space-1000 desktop:py-space-400',
                    'flex w-full justify-between',
                )}
            >
                <div
                    className={cx('middle-header__logo', 'basis-[272px]', 'h-[auto] desktop:h-[44px]', 'flex', 'items-center', 'cursor-pointer')}
                >
                    <Skeleton className="w-[74px] lg:w-[120px] h-[34px] lg:h-[52px]" width={null} height={null} />
                </div>
                <div
                    className={cx(
                        'bottom-header',
                        'tablet:max-w-[768px]',
                        'desktop:max-w-[1280px]',
                        'mobile:hidden tablet:hidden',
                        'pl-[18px]',
                    )}
                >
                    <div className="flex flex-row menu-category">
                        <div className="xs:basis-full menu-middle">
                            <MenuSkeleton />
                        </div>
                    </div>
                </div>
                <div className={cx('middle-header__statusicon')}>
                    <div className={cx('middle-header__statusicon__left-section', 'flex', 'flex-row', 'gap-space-500', 'desktop:px-2')}>
                        {/* {modules.productcompare.enabled && (
                            <div className={cx('product-compare')}>
                                <ProductCompareIcon withLink isLogin={isLogin} />
                            </div>
                        )} */}
                        <div className={cx('search-desktop', 'flex justify-center items-center')}>
                            <Skeleton width={24} height={24} />
                        </div>
                        <div id="header-shoppingBag-icon" className={cx('shopping-bag')}>
                            <Skeleton width={24} height={24} />
                        </div>
                        <div
                            className={cx(
                                'middle-header__statusicon__right-section__account',
                                'hover:cursor-pointer',
                                'flex',
                                'justify-start',
                                'group',
                                'hidden lg:block',
                            )}
                        >
                            <Skeleton width={24} height={24} />
                        </div>
                        <div className={cx('block lg:hidden', 'bottom-header-mobile__burger-menu', 'flex justify-center items-center')}>
                            <Skeleton width={24} height={24} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default DesktopHeaderSkeleton;
